import React , { lazy , Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/home/Jobs.css"
const Login = lazy(() => import("./components/login/Signin"));
const Forgotpass = lazy(() => import("./components/forgotPassword/Forgotpass"));
const Changepass = lazy(() => import("./components/forgotPassword/Changepass"));
const Register = lazy(() => import("./components/signup/Register"));
const Jobs = lazy(() => import("./components/home/Jobs"));
const Jobviewdetails = lazy(() => import("./components/home/Jobviewdetails"));
const Editprofile = lazy(() => import("./components/profile/Editprofile"));
// import Login from "./components/login/Signin";
// import Forgotpass from "./components/forgotPassword/Forgotpass";
// import Changepass from "./components/forgotPassword/Changepass";
// import Register from "./components/signup/Register";
// import Jobs from "./components/home/Jobs";
// import Jobviewdetails from "./components/home/Jobviewdetails";
// import Editprofile from "./components/profile/Editprofile";


// const isLogin = localStorage.getItem("token");
ReactDOM.render(
  <BrowserRouter>
   <Suspense fallback={<div className="loading"><span className="main_loader"></span></div>}>
    <Switch>
      <Route path={`/editprofile`} component={Editprofile} />
      <Route path={`/jobs/:id`} component={Jobviewdetails} />
      <Route path={`/register`} component={Register} />
      <Route path={`/changepass`} component={Changepass} />
      <Route path={`/forgot`} component={Forgotpass} />
      <Route path={`/login`} component={Login} />
      {/* {!isLogin ? (
        <Route path={`/`} component={Login} />
      ) : (
        <Route path={`/jobs`} component={Jobs} />
      )} */}
      <Route path={`/`} component={Jobs} />
    </Switch>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
